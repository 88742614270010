<template>
  <v-container class="youtube-player">
    <!-- PREFACE -->
    <p class="date">{{ $props.release }}</p>
    <h1>{{ $props.title }}</h1>

    <!-- PLAYER -->
    <v-row
      class="mt-5"
      justify="center"
    >
      <!-- APPROVAL INFO -->
      <div 
        v-if="!videosApprovalGiven"
        :class="{'privacy-information-mobile': isMobile(), 'privacy-information': !isMobile() }"
        :style="privacyInformationStyle"
        @click="displayVideo"
      >
        <p
          class="privacy-information-content to-front"
          @click="stopClickPropagation"
        >
          <span v-if="$i18n.locale === 'de'">Mit dem Aufruf des Videos erklärst Du Dich einverstanden, dass Deine Daten an YouTube übermittelt werden und dass Du die <a @click="goTo('privacy')">Datenschutzerklärung</a> gelesen hast.</span>
          <span v-else>By playing this video you agree that your data is transmitted to YouTube  and that you read the <a @click="goTo('privacy')">{{ $t('privacy') }}</a> information.</span>
        </p>
        <v-btn 
          class="hover-glow basic-button center-content to-front"
          @click="displayVideo"
        >
          {{ $t('loadVideo') }}
        </v-btn>
      </div>

      <!-- PLAYER -->
      <div :id="`player-${videoId}`">
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { utils } from '@/components/mixins/util-methods.mixin.js';
import Vue from 'vue';

export default {
  name: 'YoutubeVideoPlayer',
  mixins: [
    utils
  ],  
  props: {
    height: { type: Number, required: false, default: 360 },
    release: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: '' },
    videoId: { type: String, required: true },
    videoPreviewPicture: {type: String, required: true},
    width: { type: Number, required: false, default: 640 },
  },
  data: function() {
    return {
      videoWidthMobile: window.screen.width * 0.8,
      videoHeightMobile: window.screen.width / (16/9),
      videoPreviewPicturePath: require(`@/assets/pictures/video-preview/${this.$props.videoPreviewPicture}`),
    }
  },
  computed: {
    privacyInformationStyle() {
        return {
          '--image': `url(${this.videoPreviewPicturePath})`,
          '--height': `${this.videoHeight}px`,
          '--width': `${this.videoWidth}px`, 
        }
    },
    videosApprovalGiven() {
      return this.$store.getters['settings/videosApprovalGiven'];
    },
    videoWidth() {
      return this.isMobile() ? this.videoWidthMobile : this.$props.width;
    },
    videoHeight() {
      return this.isMobile() ? this.videoHeightMobile : this.$props.height;
    },
  },
  watch: {
    videosApprovalGiven: function() {
      if(this.videosApprovalGiven) {
        document.getElementById(`player-${this.$props.videoId}`).innerHTML=`<iframe width="${this.videoWidth}" height="${this.videoHeight}" src="https://www.youtube-nocookie.com/embed/${this.$props.videoId}" frameBorder="0"</iframe>`
      }
    }
  },
  mounted() {
    if(this.videosApprovalGiven) {
      document.getElementById(`player-${this.$props.videoId}`).innerHTML=`<iframe width="${this.videoWidth}" height="${this.videoHeight}" src="https://www.youtube-nocookie.com/embed/${this.$props.videoId}" frameBorder="0"</iframe>`
    }
  },
  methods: {
    /**
     * Navigate to different page and emit event for scrolling to top
     * @param {String} target name of the target page
     */
    goTo(target) {
      if(this.$router.currentRoute.name === target) {
        return;
      }
      this.$emit("scrollToTop");
      this.$router.push({ name: target });
    },
    /**
     * Sets videos to approved and adds the video to the DOM
     */
    displayVideo() {
      this.$store.commit('settings/set', {key: 'videosApprovalGiven', value: true});
      document.getElementById(`player-${this.$props.videoId}`).innerHTML=`<iframe width="${this.videoWidth}" height="${this.videoHeight}" src="https://www.youtube-nocookie.com/embed/${this.$props.videoId}" frameBorder="0"</iframe>`
    },
    /**
     * Stops propagating the click. Used here to not load video when user clicks on the written information or privacy link
     * @param {Object} event event object provided by the click event
     */
    stopClickPropagation(event) {
      event.stopPropagation();
    },
  }
};
</script>

<style lang="scss" scoped>
.center-content {
  position: relative;
  top: 25%;
  transform: translateY(-100%);
}

@media screen and (min-width: 540px) {
    .center-content {
      position: relative;
      top: 25%;
      transform: translateY(-25%);
    }
}

.date {
  font-size: 12px;
  margin: 0;
}

.privacy-information {
  background: black;
  border: 1px solid green;
  height: var(--height);
  position: relative;
  text-align: center;
  width: var(--width);

  &:before {
    content: ' ';
    background-image: var(--image);
    background-size: 100% 100%;
    height: var(--height);
    width: var(--width);
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &:hover:before {
    cursor: pointer;
    opacity: 0.25;
  }
}

.privacy-information-content {
  font-size: 12px;
  position: relative;
}

.privacy-information-mobile {
  background: black;
  border: 1px solid green;
  height: var(--height);
  position: relative;
  text-align: center;
  width: var(--width);

  &:before {
    content: ' ';
    background-image: var(--image);
    background-size: 100% 100%;
    height: var(--height);
    width: var(--width);
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0.25;
  }
}

.to-front {
  z-index: 1000;
}

</style>