<template>
    <v-row
      class="default-bottom-margin"
      :justify="$props.justify"
    >
      <a 
        v-if="$props.links.amazonLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.amazonLink"
        target="_blank"       
      >
        <img 
          class="icon"
          :style="iconStyle"
          src="@/assets/icons/amazon.png"
        />
      </a>
      <a 
        v-if="$props.links.bandcampLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.bandcampLink"
        target="_blank"       
      >
        <img 
          class="icon"
          :style="iconStyle"
          src="@/assets/icons/bandcamp.png"
        />
      </a>
      <a 
        v-if="$props.links.facebookLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.facebookLink"
        target="_blank"  
      >
        <img 
          class="icon"          
          :style="iconStyle"
          src="@/assets/icons/facebook.png"
        />
      </a>
      <a 
        v-if="$props.links.instagramLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.instagramLink"
        target="_blank"  
      >
        <img 
          class="icon"          
          :style="iconStyle"
          src="@/assets/icons/instagram.png"
        />
      </a>
      <a 
        v-if="$props.links.soundcloudLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.soundcloudLink"
        target="_blank"  
      >
        <img 
          class="icon"          
          :style="iconStyle"
          src="@/assets/icons/soundcloud.png"
        />
      </a>
      <a 
        v-if="$props.links.spotifyLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.spotifyLink"
        target="_blank"
      >
        <img 
          class="icon"
          :style="iconStyle"
          src="@/assets/icons/spotify.png"
        />
      </a>
      <a
        v-if="$props.links.twitterLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.twitterLink"
        target="_blank"         
      >
        <img 
          class="icon"
          :style="iconStyle"
          :src="require('@/assets/icons/twitter.png')"
        />
      </a>
      <a
        v-if="$props.links.youtubeLink"
        class="hover-glow"
        :style="glowStyle"
        :href="$props.links.youtubeLink"
        target="_blank" 
      >
        <img 
          class="icon"         
          :style="iconStyle"
          :src="require('@/assets/icons/youtube.png')"
        />
      </a>
      <a
        v-if="$props.links.moreLink"
        class="more"
        :href="$props.links.moreLink"
        target="_blank"
      >
        {{ $t('more') }}
      </a>
    </v-row>
</template>

<script>
export default {
    name: 'SocialMediaLinks',
    props: {
      links: {type: Object, required: true},
      size: {type: String, required: false, default: '45px'},
      margin: {type: String, required: false, default: '10px'},
      justify: {type: String, required: false, default: 'center'},
    },
    computed: {
      iconStyle() {
        return {
          'height': this.$props.size,
          'margin-right': this.$props.margin,
        }
      },
      glowStyle() {
        return {
          'margin-left': this.$props.margin,
          'margin-right': this.$props.margin,
          'width': this.$props.size,
          'height': this.$props.size,
        }
      }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.more {
  color: $highlight-color;
  margin-left: 4px;
}

.hover-glow {
  position: relative;
  display: block;
  text-align: center;
  background: $highlight-color;
  border-radius: 50%;
  transition: .5s;
}

.hover-glow:before {
  content: '';
  position: absolute;
  border-radius:50%;
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}

.hover-glow:hover:before {
  transform: scale(1.2);
  box-shadow: 0 0 15px $highlight-color;
  filter: blur(3px);
}

.hover-glow:hover {
  color: #23d300;
  box-shadow: 0 0 15px $highlight-color;
  text-shadow: 0 0 15px $highlight-color;
}
</style>