<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('discography') }}</h1>

    <!-- TABS -->
      <v-tabs
        background-color="transparent"
        centered
        dark
        v-model="currentTab"
      >
        <v-tab
          class="hover-glow tab-item"
          v-for="tab in tabs"
          :key="tab.id"
          v-ripple="{'class': 'green--text'}"
        >
          {{ tab.label }}
        </v-tab>
        <v-tabs-items
          class="mt-5"
          id="tabs-items"
          v-model="currentTab"
          dark
        >
          <v-tab-item>
            <!-- VIDEOS -->
            <v-container>
              <h1 class="default-bottom-margin">Videos</h1>
              <v-row
                justify="center"
                v-for="(video, index) in chunkedVideosData"
                :key="index"
              >
                <v-col>
                  <youtube-video-player 
                    :title="video[0].title"
                    :videoId="video[0].videoId"
                    :videoPreviewPicture="video[0].previewPicture"
                    :release="video[0].release"
                    :width="videoWidth"
                    :height="videoHeight"
                  />
                </v-col>
                <v-col>
                  <youtube-video-player 
                    v-if="video[1]"
                    :title="video[1].title"
                    :videoId="video[1].videoId"
                    :videoPreviewPicture="video[1].previewPicture"
                    :release="video[1].release"
                    :width="videoWidth"
                    :height="videoHeight"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <!-- ALBUMS -->
          <v-tab-item>
            <v-container>
              <h1 class="default-margin-bottom">Albums</h1>
            </v-container>
            <v-container
              v-for="(album, index) in albumsData"
              :key="index"
            >
              <album-details
                :album="album"
              />
            </v-container>
          </v-tab-item>

          <!-- SINGLES -->
          <v-tab-item>
            <v-container>
              <h1 class="default-margin-bottom">Singles</h1>
            </v-container>
            <v-container
              v-for="(single, index) in singlesData"
              :key="index"
            >
              <album-details
                :album="single"
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
  </v-container>
</template>

<script>
import albumDetails from '@/components/album-details.component.vue';
import albumsData from '@/assets/data/albums-data.json';
import singlesData from '@/assets/data/singles-data.json';
import videosData from '@/assets/data/videos-data.json';
import youtubeVideoPlayer from '@/components/youtube-video-player.component.vue';

const chunk = require('chunk');

export default {
  name: 'Discography',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Übersicht über die Videos und Alben des Musikprojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
  components: {
    albumDetails,
    youtubeVideoPlayer,
  },
  data: function () {
    return {
      currentTab: 'albums',
      albumsData: albumsData,
      singlesData: singlesData,
      videosData: videosData,
      videoHeight: 250,
      videoWidth: 400,
      videoWidthMobile: window.screen.width * 0.8,
      videoHeightMobile: window.screen.width / (16/9),
      tabs: [
        {'id': 'videos', 'label': 'Videos',},
        {'id': 'albums', 'label': this.$i18n.t('albums'),},        
        {'id': 'singles', 'label': 'Singles',}        
      ]
    }
  },
  computed: {
    chunkedVideosData() {
      return chunk(this.videosData, 2)
    },
  },
}
</script>

<style lang="scss" scoped>
#tabs-items {
  background-color: transparent;
}

.tab-item {
  margin-top: auto;
  margin-bottom: auto;
  height: 90%;
}
</style>