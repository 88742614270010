<template>
  <v-container class="album-details glowing-border default-bottom-margin">
    <v-row>
      <v-col cols="12" sm="6">
        <v-container>
          <!-- ALBUM COVER -->
          <p>
            <v-img
              :aspect-ratio="1/1"
              :src="require(`@/assets/pictures/album-covers/${$props.album.cover}`)"
            />
          </p>

          <!-- RELEASE INFO -->
          <p class="mb-1">{{ $t('artist') }}: {{ $props.album.artist }}</p>
          <p class="mb-1">{{ $t('title') }}: {{ $props.album.title }}</p>
          <p>{{ $t('release') }}: {{ $props.album.release }}</p>
          <p class="ml-2">
            <social-media-links
              :size="'30px'"
              :margin="'3px'"
              :links="links"
              :justify="'start'"
            />
          </p>
        </v-container>
      </v-col>

      <!-- TRACKLIST -->
      <v-col cols="12" sm="6">
        <h1 class="mt-7">{{ $props.album.artist }} - {{ $props.album.title }}</h1>
        <v-container>
          <p v-for="(track, index) in $props.album.tracklist" :key="index">
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs}">
                    <span v-bind="attrs" v-on="on">{{ index + 1 }}. {{ $props.album.tracklist[index].title }}</span>
                </template>

                <!-- HOVER BOX -->
                <p v-for="(value, attribute) in $props.album.tracklist[index]" :key="attribute">
                  {{ $t(attribute) }}: {{ value }}
                </p>
            </v-tooltip>
          </p>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import socialMediaLinks from '@/components/social-media-links.component.vue';

export default {
  name: 'AlbumDetails',
  components: {
    socialMediaLinks,
  },
  props: {
    album: {type: Object, required: true},
  },
  data: function() {
    return {
      links: {
        'bandcampLink': this.$props.album.bandcampLink,
        'soundcloudLink': this.$props.album.soundcloudLink,
        'spotifyLink': this.$props.album.spotifyLink,
        'youtubeLink': this.$props.album.youtubeLink,
        'moreLink': this.$props.album.moreLink,
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.album-details {
  background-color: $content-background-color;
}
</style>